<template>
	<div class="regulations">
        <v-container>
			<VueEditor 
                v-model="text"
                v-if="getRole === 'administrator' || getRole === 'moderator'"
                class="wordbreak" 
            />
            <v-btn
                color="blue"
                @click="sendContent"
                :loading="isLoaded"
                v-if="getRole === 'administrator' || getRole === 'moderator'"

                class="mt-1 mb-4 mb-1 mr-1 white--text"
            >
                Edytuj
            </v-btn>
            <v-card 
                class="pa-3" 
                v-if="getRole !== 'administrator' && getRole !== 'moderator'"
            >
                <v-card-subtitle v-html="text" class="text-left">
                </v-card-subtitle>
            </v-card>

        </v-container>
    </div>
</template>

<script>
import { VueEditor } from "vue2-editor";
import io from 'socket.io-client';

const token = localStorage.getItem('user');

export default {
    components: {
		VueEditor,
    },
    computed: {
        getRole(){
            return this.$store.getters.getUserRole
        }
    },
    data() {
        return {
            socket: io(this.url),
            text: '',
            isLoaded: false,
        }
    },
    methods: {
        getContent(){
            this.$axios.get(`${this.url}api/system-info/`, { headers: { 'x-access-token': token }})
            .then((resp)=> {
                this.text = resp.data.content
            })
        },
        sendContent(){
            this.isLoaded = true;
            this.$axios.put(`${this.url}api/system-info`,{
                text : this.text, 
				}, { headers: { 'x-access-token': token }})
                .then(()=> {
                    this.isLoaded = false;
            })
        }
    },
    created() {
        this.getContent();
        this.socket.on('updatedDocumentsInfo', () => {
            this.getContent();
            console.log('test');
        })
    },
}
</script>

<style lang="scss" scoped>
	.wordbreak {
		word-wrap: break-word;
		a{
			word-break: break-word;
		}
	}
</style>